import { html, nothing, render } from "lit-html"
import { virtual, useState, useEffect } from "haunted"
import { SelectBox } from "../utils/select-box"
import { repeat } from "lit-html/directives/repeat"

const RECURRING = "recurring"
const CUSTOM_DATES = "custom"
const DEFAULT_CUSTOM_DATE_FORM = {
  custom_location: false,
  in_person: true,
  remote: true,
  link: "",
  location: {
    name: "",
    street_address: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
  },
  errors: { location: {} },
}
let STATE_OPTIONS = nothing
let STATE_VALUE = ""
let START_TIME_REQUIRED = false
const noop = () => {}

const FieldWrapper = ({ label, field }) => {
  return html`
    <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
      <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
        ${label}
      </label>
      <div class="w-full md:w-3/4">${field}</div>
    </div>
  `
}

const DateTimeFormField = virtual(
  ({
    name,
    type,
    value,
    form = DEFAULT_CUSTOM_DATE_FORM,
    placeholder = "",
    onChange = noop,
    required = false,
    key = "",
  }) => {
    const [fieldErr, setFieldErr] = useState(!!form.errors[name])
    const [error, setError] = useState(form.errors[name] || ``)

    const onChangeValue = (e) => {
      onChange(e)
      if (required) {
        setFieldErr(!e.target.value)
        setError(`Please enter a valid ${type}`)
      }
    }

    return html`
      <div class="relative">
        <input
          id="id_${name}${key}"
          type="${type}"
          name="${name}"
          ?form=${!!key}
          ?required=${required}
          .value=${value}
          @change=${onChangeValue}
        />
        ${placeholder
          ? html`
              <label
                for="id_${name}${key}"
                class="block absolute top-4half right-4half text-sm"
                >${placeholder}</label
              >
            `
          : nothing}
        ${required & fieldErr
          ? html`
              <span class="block mt-2 text-red text-sm field-error">
                ${error}
              </span>
            `
          : nothing}
      </div>
    `
  }
)

const DateTimeFieldWrapper = ({
  label,
  dateField,
  timeField,
  required = false,
}) => {
  return html`
    <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
      <label
        class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold ${required
          ? "required-label"
          : ""}"
      >
        ${label}
      </label>
      <div
        class="${dateField
          ? `inline-block`
          : `hidden`} md:inline-block w-full md:w-3/8 md:pr-5 field"
      >
        ${dateField ? DateTimeFormField(dateField) : nothing}
      </div>
      <div class="w-full md:w-3/8 md:pl-5 field">
        ${DateTimeFormField(timeField)}
      </div>
    </div>
  `
}

const LinkFieldWrapper = virtual(
  ({ label, field, form = DEFAULT_CUSTOM_DATE_FORM, onChange = noop }) => {
    const [linkErr, setLinkErr] = useState(
      field ? field.querySelector(".field-error")?.innerText : form.errors.link
    )
    const validateLink = (value) => {
      if (value === "") {
        setLinkErr("")
        return
      }
      try {
        new URL(value)
        setLinkErr("")
      } catch {
        setLinkErr("Please enter a valid URL.")
      }
    }

    const name = "link"
    const value = field
      ? field.querySelector("input").value
      : form
      ? form.link
      : ""
    return html`
      <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
        <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
          ${label}
        </label>
        <div class="w-full md:w-3/4">
          <input
            name="${name}"
            type="url"
            .value=${value}
            ?form=${!field}
            @change="${(e) => {
              const value = e.target.value
              onChange("link", value)
              validateLink(value)
            }}}"
          />
        </div>
      </div>
      ${linkErr
        ? FieldWrapper({
            name: "link-error",
            label: "",
            field: html`
              <span class="block -mt-5 text-red text-sm field-error">
                ${linkErr}
              </span>
            `,
          })
        : nothing}
    `
  }
)

const selectStateOptions = (selectedValue = STATE_VALUE) =>
  Array.from(STATE_OPTIONS).map(
    (option) => html`
      <option
        value="${option.value}"
        ?selected=${option.value === selectedValue}
      >
        ${option.text}
      </option>
    `
  )

const StateFieldWrapper = ({
  label,
  onChangeState = noop,
  form = null,
}) => html`
  <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
    <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
      ${label}
    </label>
    <div class="w-full md:w-3/4">
      <select
        class="input"
        name="state"
        ?form=${!!form}
        @change=${onChangeState}
      >
        ${selectStateOptions(form?.location.state)}
      </select>
    </div>
  </div>
`

const LocationFieldsWrappers = virtual(
  ({
    key = "",
    isInPerson = false,
    isRemote = false,
    fields,
    setFormFieldValue = noop,
  }) => {
    const [inPerson, setInPerson] = useState(isInPerson)
    const [remote, setRemote] = useState(isRemote)

    useEffect(() => {
      setFormFieldValue("in_person", inPerson)
    }, [inPerson])

    useEffect(() => {
      setFormFieldValue("remote", remote)
    }, [remote])

    return html`
      <div class="field-wrapper flex py-8 flex-wrap md:flex-nowrap">
        <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
          Event type
        </label>
        <div class="mr-10 flex items-center">
          <input
            type="checkbox"
            name="in_person"
            id="id${key}_in_person"
            ?form=${!!key}
            .checked="${inPerson}"
            @click="${() => setInPerson(!inPerson)}"
          />
          <label for="id${key}_in_person" class="ml-3">In-person event</label>
        </div>
        <div class="flex items-center">
          <input
            type="checkbox"
            name="remote"
            id="id${key}_remote"
            ?form=${!!key}
            .checked="${remote}"
            @click="${() => setRemote(!remote)}"
          />
          <label for="id${key}_remote" class="ml-3">Remote event</label>
        </div>
      </div>
      <div class="${remote ? "block" : "hidden"}">
        ${LinkFieldWrapper(fields.link)}
      </div>
      <div class="${inPerson ? "block" : "hidden"}">
        ${FieldWrapper(fields.location_name)}
        ${FieldWrapper(fields.street_address)} ${FieldWrapper(fields.address2)}
        ${FieldWrapper(fields.city)} ${StateFieldWrapper(fields.state)}
        ${FieldWrapper(fields.zip_code)}
      </div>
    `
  }
)

const CustomDateLabel = (customIndex = 1) => html`
  <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
    <label class="mt-6 md:mb-0 w-full md:w-1/4 md:pr-4 text-gray-450 text-sm">
      CUSTOM DATE #${customIndex}
    </label>
    <div class="w-full md:w-3/4 border-gray-border border-t-px"></div>
  </div>
`

const CustomLocationForm = virtual(
  ({ key, form = DEFAULT_CUSTOM_DATE_FORM, setFormFieldValue }) => {
    const [customLocation, setCustomLocation] = useState(form.custom_location)

    useEffect(() => {
      setFormFieldValue("custom_location", customLocation)
    }, [customLocation])

    const customLocationFormField = (name, label, type = "text") => {
      const [locationErr, setLocationErr] = useState(
        form.errors.location[name] || ``
      )
      const onChangeLocationField = (e) => {
        setFormFieldValue(name, e.target.value, true)
        setLocationErr(``)
      }
      return {
        name,
        label,
        field: html`
          <input
            name="${name}"
            form="custom-location-form"
            type="${type}"
            .value=${form.location[name]}
            @change=${onChangeLocationField}
          />
          ${locationErr
            ? html`
                <span class="block text-red text-sm field-error"
                  >${locationErr}</span
                >
              `
            : nothing}
        `,
      }
    }

    return html`
      <div class="field-wrapper flex pt-6 pb-4 flex-wrap md:flex-nowrap">
        <label class="md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
          Custom location
        </label>
        <div class="w-full md:w-3/4 pt-2">
          <span class="button-toggle">
            <input
              type="checkbox"
              class="toggle"
              name="custom-location"
              form="custom-location-form"
              .checked="${customLocation}"
              @click=${() => setCustomLocation(!customLocation)}
            />
          </span>
        </div>
      </div>
      <div
        class="border-px border-gray-border rounded-md px-6 py-5 ml-4 mb-4 ${customLocation
          ? ""
          : "hidden"}"
      >
        <label class="mt-2 text-gray-450 text-sm">
          CUSTOM LOCATION DETAILS
        </label>
        ${LocationFieldsWrappers({
          key,
          setFormFieldValue,
          isInPerson: form.in_person,
          isRemote: form.remote,
          fields: {
            link: {
              label: "Event link",
              form,
              onChange: setFormFieldValue,
            },
            location_name: customLocationFormField("name", "Location name"),
            street_address: customLocationFormField(
              "street_address",
              "Street name"
            ),
            address2: customLocationFormField("address2", "Address 2"),
            city: customLocationFormField("city", "City"),
            state_text: customLocationFormField("state", "State"),
            state: {
              form,
              label: "State",
              onChangeState: (e) => {
                setFormFieldValue("state", e.target.value, true)
              },
            },
            zip_code: customLocationFormField("zip_code", "Zip code"),
          },
        })}
      </div>
    `
  }
)

const CustomDateForm = virtual(
  ({
    key,
    form = DEFAULT_CUSTOM_DATE_FORM,
    customIndex,
    originalFields,
    onRemoveCustomDateForm,
    onUpdateCustomDateForm,
  }) => {
    const [inputCustomDates, setInputCustomDates] = useState(form)

    useEffect(() => {
      onUpdateCustomDateForm(key, { ...inputCustomDates })
    }, [inputCustomDates])

    const setFormFieldValue = (field, value, location = false) => {
      if (location) {
        setInputCustomDates({
          ...inputCustomDates,
          location: {
            ...inputCustomDates.location,
            [field]: value,
          },
        })
      } else {
        setInputCustomDates({
          ...inputCustomDates,
          [field]: value,
        })
      }
    }

    const customDateTimeFormField = (name, type, value) => ({
      key,
      form,
      name,
      type,
      value,
      required: name == "start_time" ? START_TIME_REQUIRED : false,
      onChange: (e) => {
        setFormFieldValue(name, e.target.value)
      },
    })

    return html`
      <div class="relative">
        <input
          name="custom_dates"
          type="hidden"
          .value="${JSON.stringify(inputCustomDates)}"
        />
        <a
          class="absolute right-0 top-20 text-red cursor-pointer"
          @click="${() => {
            onRemoveCustomDateForm(key)
          }}"
        >
          <i class="mr-3 far fa-circle-minus"></i>
          <span class="font-bold">Remove</span>
        </a>
        ${CustomDateLabel(customIndex)}
        ${DateTimeFieldWrapper({
          label: "Start",
          required: true,
          dateField: {
            key,
            form,
            name: originalFields.start_date.name,
            type: "date",
            value: form["start_date"],
            required: true,
            onChange: (e) => {
              setFormFieldValue(originalFields.start_date.name, e.target.value)
            },
          },
          timeField: customDateTimeFormField(
            originalFields.start_time.name,
            "time",
            form["start_time"],
            key
          ),
        })}
        ${DateTimeFieldWrapper({
          label: "End",
          dateField: originalFields.end_date
            ? customDateTimeFormField(
                originalFields.end_date.name,
                "date",
                form["end_date"],
                key
              )
            : null,
          timeField: customDateTimeFormField(
            originalFields.end_time.name,
            "time",
            form["end_time"],
            key
          ),
        })}
        ${CustomLocationForm({
          key,
          form,
          inputCustomDates,
          setFormFieldValue,
        })}
      </div>
    `
  }
)

const CustomDateLocationForms = virtual(
  ({
    forms,
    originalFields,
    onRemoveCustomDateForm,
    onAddCustomDateForm,
    onUpdateCustomDateForm,
  }) => {
    return html`
      <div class="custom-dates">
        ${repeat(
          Object.entries(forms),
          ([key, _]) => key, // eslint-disable-line no-unused-vars
          // eslint-disable-next-line no-unused-vars
          ([key, form], index) => html`
            ${CustomDateForm({
              key,
              form,
              customIndex: index + 2,
              originalFields,
              onRemoveCustomDateForm,
              onUpdateCustomDateForm,
            })}
          `
        )}
        <div class="flex py-4 flex-wrap md:flex-nowrap flex-row-reverse">
          <div class="w-full md:w-3/4 border-gray-border border-t-px"></div>
          <a
            @click=${onAddCustomDateForm}
            class="cursor-pointer mt-4 w-full md:w-1/4 md:pr-4"
          >
            <i class="fas fa-plus"></i>
            <span class="font-bold">Add another date</span>
          </a>
        </div>
      </div>
    `
  }
)

const DateLocationFieldsWrapper = virtual(
  ({ fields, occurrenceType, showCustomDateLabel = false }) => {
    const shouldBind =
      fields.start_date.field.querySelector("input")?.value &&
      fields.end_date?.field.querySelector("input")?.value
    const [bindStartEndDateAttempt, setBindStartEndDateAttempt] = useState(
      shouldBind ? 1 : 0
    )

    useEffect(() => {
      if (shouldBind && bindStartEndDateAttempt) {
        const $startDateInput = document.querySelector("input#id_start_date")
        const $endDateInput = document.querySelector("input#id_end_date")
        if ($startDateInput?.value && $endDateInput?.value) {
          const duration =
            new Date($endDateInput.value) - new Date($startDateInput.value)

          $startDateInput.addEventListener("change", (e) => {
            if (new Date($startDateInput.value).toString() != "Invalid Date") {
              const startDate = new Date(e.target.value)
              $endDateInput.value = new Date(startDate.getTime() + duration)
                .toISOString()
                .substring(0, 10)
            }
          })
          setBindStartEndDateAttempt(0)
        } else {
          setBindStartEndDateAttempt(bindStartEndDateAttempt + 1)
        }
      }
    }, [bindStartEndDateAttempt])

    return html`
      ${showCustomDateLabel ? CustomDateLabel() : nothing}
      ${DateTimeFieldWrapper({
        label: "Start",
        required: true,
        dateField: {
          name: fields.start_date.name,
          type: "date",
          value: fields.start_date.field.querySelector("input").value,
          placeholder: fields.start_date.field.querySelector("p").innerHTML,
          required: true,
        },
        timeField: {
          name: fields.start_time.name,
          type: "time",
          value: fields.start_time.field.querySelector("input").value,
          placeholder: fields.start_time.field.querySelector("p").innerHTML,
          required: START_TIME_REQUIRED,
        },
      })}
      ${DateTimeFieldWrapper({
        label: "End",
        dateField: fields.end_date
          ? {
              name: fields.end_date.name,
              type: "date",
              value: fields.end_date.field.querySelector("input").value,
              placeholder: fields.end_date.field.querySelector("p").innerHTML,
            }
          : null,
        timeField: {
          name: fields.end_time.name,
          type: "time",
          value: fields.end_time.field.querySelector("input").value,
          placeholder: fields.end_time.field.querySelector("p").innerHTML,
        },
      })}
      ${occurrenceType === RECURRING ? fields.patterns.field : nothing}
      ${LocationFieldsWrappers({
        fields,
        isInPerson: fields.in_person.field.querySelector("input").checked,
        isRemote: fields.remote.field.querySelector("input").checked,
      })}
    `
  }
)

const OccurrencesGroup = virtual(({ fields }) => {
  const occurrenceTypeProps = fields.occurrence_type
  const occurrenceTypeOptions = Array.from(
    occurrenceTypeProps.field.querySelectorAll("option")
  ).map(($option) => ({
    label: $option.text,
    value: $option.value,
    onClick: () => {
      setOccurrenceType($option.value)
    },
  }))
  const selectedOccurrenceType = occurrenceTypeProps.field.querySelector(
    "select"
  ).value

  let counter = 0
  const customDatesErrors = JSON.parse(
    fields.custom_dates?.field.querySelector("p.text-red")?.innerText || "{}"
  )
  const customDates = fields.custom_dates
    ? [...fields.custom_dates.field.querySelectorAll("option")].map(
        (option, index) => ({
          ...JSON.parse(option.value),
          errors: customDatesErrors[`${index}`] || {},
        })
      )
    : []

  const [occurrenceType, setOccurrenceType] = useState(selectedOccurrenceType)
  const [customDateForms, setCustomDateForms] = useState(
    Object.fromEntries(
      customDates.map((form) => [`key-${Date.now()}-${counter++}`, form])
    )
  )
  const onAddCustomDateForm = () => {
    setCustomDateForms({
      ...customDateForms,
      [`key-${Date.now()}-${counter++}`]: DEFAULT_CUSTOM_DATE_FORM,
    })
  }

  const onRemoveCustomDateForm = (key) => {
    const newForms = { ...customDateForms }
    delete newForms[key]
    setCustomDateForms(newForms)
  }

  const onUpdateCustomDateForm = (key, form) => {
    setCustomDateForms({
      ...customDateForms,
      [key]: form,
    })
  }

  return html`
    ${FieldWrapper(fields.timezone)}
    ${FieldWrapper({
      ...occurrenceTypeProps,
      field: html`
        <input type="hidden" name="occurrence_type" .value=${occurrenceType} />
        ${SelectBox({
          width: "100%",
          options: occurrenceTypeOptions,
          selectedOption: occurrenceTypeOptions.find(
            (option) => option.value === selectedOccurrenceType
          ),
        })}
      `,
    })}
    ${DateLocationFieldsWrapper({
      fields,
      occurrenceType,
      showCustomDateLabel: occurrenceType === CUSTOM_DATES,
    })}
    ${occurrenceType === CUSTOM_DATES
      ? CustomDateLocationForms({
          forms: customDateForms,
          originalFields: fields,
          onAddCustomDateForm,
          onRemoveCustomDateForm,
          onUpdateCustomDateForm,
        })
      : nothing}
    <div class="pb-6 border-b-px border-gray-border"></div>
  `
})

export default function renderOccurrenceTypeInput(el) {
  const formEl = el.closest("form")
  const $group = el.closest(`.group`)
  const fields = Object.fromEntries(
    [...$group.querySelectorAll(".field-wrapper")].map(($fieldWrapper) => {
      const $label = $fieldWrapper.querySelector(`label`)
      const fieldName = $label.htmlFor.match(/id_(.+)/)[1]
      const $field = $fieldWrapper.querySelector(`.field`)
      return [
        fieldName,
        { name: fieldName, label: $label.textContent.trim(), field: $field },
      ]
    })
  )
  fields.timezone = fields.timezone || fields.tz
  fields.start_date = fields.start_date || fields.date

  const stateSelect = fields.state.field.querySelector("select")
  STATE_OPTIONS = stateSelect.options
  STATE_VALUE = stateSelect.value
  START_TIME_REQUIRED = fields.start_time.field.querySelector("input").required

  const mount = document.createElement("div")
  render(OccurrencesGroup({ fields }), mount)
  $group.replaceWith(...mount.childNodes)

  // bind outer form submission
  formEl.addEventListener("submit", (e) => {
    e.preventDefault()
    const errors = formEl.querySelectorAll(".field-error")
    let error = null
    errors.forEach((err) => {
      if (!err.closest(".hidden")) {
        error = err
      }
    })

    if (error) {
      error.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      e.target.submit()
    }
  })
}
